export const packages = [
    {
        name: "None",
        price: 0,
    },
    {
        name: "Starter SEO Package",
        price: 199,
    },
    {
        name: "Growth SEO Package",
        price: 299,
    },
    {
        name: "Advanced SEO Package",
        price: 399,
    },
]


export const sslTypes = [
    {
        type: "None",
        annualCost: 0,
        eCommerceAnnualCost: 0,
        deprecated: false
    },
    {
        type: "SSL Only",
        annualCost: 90,
        eCommerceAnnualCost: 120,
        deprecated: true
    },
    {
        type: "Just GDPR policies",
        annualCost: 90,
        eCommerceAnnualCost: 120,
        deprecated: true
    },
    {
        type: "SSL & GDPR package",
        annualCost: 150,
        eCommerceAnnualCost: 180,
        deprecated: false
    }
]

export const hostingTypes = [
    {
        type: "None",
        diskQuota: null,
        monthlyCost: 0,
        annualCost: 0,
    },
    {
        type: "One page",
        diskQuota: "0.5GB",
        monthlyCost: 120,
        annualCost: 120
    },
    {
        type: "Small",
        diskQuota: "1GB",
        monthlyCost: 160,
        annualCost: 160
    },
    {
        type: "Medium",
        diskQuota: "3GB",
        monthlyCost: 315,
        annualCost: 315
    },
    {
        type: "Large",
        diskQuota: "6GB",
        monthlyCost: 475,
        annualCost: 475
    },
    {
        type: "Extra large",
        diskQuota: "8GB",
        monthlyCost: 650,
        annualCost: 650
    },
    {
        type: "Mega",
        diskQuota: "8GB+",
        monthlyCost: null,
        annualCost: null,
    }
]

export const additionsCosts = [
    {
        name: "SEO Boost",
        price: 299,
        interval: null,
    },
    {
        name: "Google Review Widget",
        price: 90,
        interval: null,
    },
    {
        name: "Blog integration",
        price: 90,
        interval: null,
    },
    {
        name: "Live chat",
        price: 60,
        interval: null,
    },
    {
        name: "Appointment bookings",
        price: 120,
        interval: null,
    },
    {
        name: "Newsletter integration",
        price: 60,
        interval: null,
    },
    {
        name: "Google my business setup",
        price: 90,
        interval: null,
    },
    {
        name: "Training guide",
        price: 90,
        interval: null,
        hidden: true,
    },
    {
        name: "Training video",
        price: 90,
        interval: null,
        hidden: true,
    },
    {
        name: "Custom Zoom training",
        price: 120,
        interval: null,
        hidden: true,
    },
    {
        name: "Accelerated performance & security",
        price: 60,
        interval: "per month",
        hidden: true,
    }
]

export const siteManagementCosts = [
    {
        name: "Training guide",
        price: 90,
        interval: null,
    },
    {
        name: "Training video",
        price: 90,
        interval: null,
    },
    {
        name: "Custom Zoom training",
        price: 120,
        interval: null,
    },
    {
        name: "Accelerated performance & security",
        price: 60,
        interval: "per month",
    },
]

export const carePackages = [
    {
        name: "None",
        price: 0,
        interval: null,
    },
    {
        name: "Monthly Care Service",
        price: 50,
        interval: "monthly",
    },
    {
        name: "Monthly Care Plus Service",
        price: 100,
        interval: "monthly",
    },
    {
        name: "Annual Update Service",
        price: 150,
        interval: "annually",
    }
]